<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div
                class="d-flex flex-column flex-lg-row justify-content-between align-items-center"
            >
                <h3 class="fw-bold mb-4">Deal Order List</h3>
                <div class="d-flex">
                    <h3 class="fw-bold mb-4 me-2">Total Income</h3>
                    <h3 class="fw-bold mb-4 text-green">
                        {{ currency(lists?.total) }}
                    </h3>
                </div>
            </div>
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-lg-5 my-2">
                            <div class="position-relative">
                                <input
                                    type="text"
                                    class="form-control form-control-lg pl-search"
                                    placeholder="Cari nomor resi"
                                    v-model="search"
                                    @keyup.enter="(currentPage = 1), getLists()"
                                />
                                <span class="search-icon left">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3 my-2">
                            <button
                                class="btn btn-outline-black btn-filter px-4 w-100"
                                @click="isFilter = !isFilter"
                            >
                                <img
                                    src="../../assets/dashboard/filter.png"
                                    class="me-1"
                                    width="18"
                                    alt=""
                                />
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div
                        class="d-flex align-items-center justify-content-lg-end justify-content-center"
                    >
                        <div class="me-2">Rows per page</div>
                        <div>
                            <select
                                class="form-control form-select w-select"
                                v-model="perPage"
                                @change="(currentPage = 1), getLists()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box mt-3" v-if="isFilter">
                <h5 class="fw-bold">Filter Data</h5>
                <div class="row">
                    <div class="col-lg-3 my-2">
                        <label class="fw-bold mb-2">Tanggal Order</label>
                        <DateRangePicker
                            placeholder-text="Pilih tanggal order"
                            v-model="orderDate"
                        />
                    </div>
                    <div class="col-lg-3 my-2">
                        <label class="fw-bold mb-2">Tipe Layanan</label>
                        <multiselect
                            v-model="serviceType"
                            :options="serviceTypes"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            placeholder="Pilih tipe layanan"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-3 my-2">
                        <label class="fw-bold mb-2">Kategori Order</label>
                        <multiselect
                            v-model="orderCategory"
                            :options="orderCategories"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            placeholder="Pilih kategori order"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-3 my-2">
                        <label class="fw-bold mb-2">CS Specialist</label>
                        <multiselect
                            v-model="specialist"
                            :options="specialists"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            placeholder="Pilih cs specialist"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-3">
                        <div class="row">
                            <div class="col-lg-6 my-2">
                                <button
                                    class="btn btn-outline-black w-100"
                                    :disabled="
                                        !orderDate.from &&
                                        !orderDate.to &&
                                        !serviceType &&
                                        !orderCategory &&
                                        !specialist
                                    "
                                    @click="
                                        (currentPage = 1),
                                            (orderDate.from = null),
                                            (orderDate.to = null),
                                            (serviceType = null),
                                            (orderCategory = null),
                                            (specialist = null),
                                            getLists()
                                    "
                                >
                                    Clear
                                </button>
                            </div>
                            <div class="col-lg-6 my-2">
                                <button
                                    class="btn btn-green w-100"
                                    :disabled="
                                        !orderDate.from &&
                                        !orderDate.to &&
                                        !serviceType &&
                                        !orderCategory &&
                                        !specialist
                                    "
                                    @click="(currentPage = 1), getLists()"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="isLoading">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table
                    class="table table-bordered mt-4"
                    v-if="lists?.list_data?.length > 0"
                >
                    <thead class="table-light">
                        <tr>
                            <th class="text-center">No</th>
                            <th class="text-center">Tanggal Deal</th>
                            <th class="text-center">Tipe</th>
                            <th class="text-center">Nama Leads</th>
                            <th class="text-center">CS Specialist</th>
                            <th class="text-center">Nominal</th>
                            <th class="text-center">Nama Customer</th>
                            <th class="text-center">Nama Pengirim</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(list, index) in lists?.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <div
                                        class="rounded-pill px-3 py-1"
                                        :class="
                                            list.category === 'repeat'
                                                ? 'red-badge'
                                                : 'green-badge'
                                        "
                                    >
                                        {{ list.category_label }}
                                    </div>
                                    <p class="mt-2 mb-0">
                                        {{ formatDate(list.date) }}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <div
                                        class="rounded-pill px-3 py-1"
                                        :class="
                                            list.service === 'trawlpack'
                                                ? 'red-badge'
                                                : list.service === 'trawltruck'
                                                ? 'green-badge'
                                                : 'blue-badge'
                                        "
                                    >
                                        {{
                                            list.service === "trawlpack"
                                                ? "Troben Cargo"
                                                : list.service === "trawltruck"
                                                ? "Troben Truck"
                                                : "Troben Carier"
                                        }}
                                    </div>
                                    <router-link
                                        class="link mt-2"
                                        :to="
                                            '/crm/order/detail/?id=' +
                                            list.id +
                                            '&service=' +
                                            list.service
                                        "
                                        >{{ list.receipt_code }}</router-link
                                    >
                                </div>
                            </td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <router-link
                                        :to="
                                            '/crm/customers/leads/detail/' +
                                            list.lead_id
                                        "
                                        class="mb-0 fw-bold"
                                    >
                                        {{ list.leads_name }}
                                        <img
                                            src="../../assets/eye.svg"
                                            alt="eye"
                                        />
                                    </router-link>
                                    <p class="mb-0 timeline-title">
                                        {{ list.leads_phone }}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <p class="mb-0 fw-bold">
                                        {{ list.crm_specialist_name }}
                                    </p>
                                </div>
                            </td>
                            <td>{{ currency(list.amount) }}</td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <p class="mb-0 fw-bold">
                                        {{ list.customer_name }}
                                    </p>
                                    <p class="mb-0 timeline-title">
                                        {{ list.customer_phone }}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <p class="mb-0 fw-bold">
                                        {{ list.account_name }}
                                    </p>
                                    <p class="mb-0 timeline-title">
                                        {{ list.account_phone }}
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    class="d-flex flex-column justify-content-center align-items-center mt-5"
                    v-else
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <p
                    class="cursor-pointer"
                    :class="{ 'pagination-disabled': currentPage === 1 }"
                    @click="
                        currentPage > 1 && handlePageChange(currentPage - 1)
                    "
                >
                    <i class="fa fa-angle-left me-2"></i>Previous
                </p>
                <p class="mx-4">{{ currentPage }} of {{ totalPages }}</p>
                <p
                    class="cursor-pointer"
                    :class="{
                        'pagination-disabled': currentPage === totalPages,
                    }"
                    @click="
                        currentPage < totalPages &&
                            handlePageChange(currentPage + 1)
                    "
                >
                    Next <i class="fa fa-angle-right ms-2"></i>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import DateRangePicker from "../../components/DateRangePicker.vue";
import Multiselect from "vue-multiselect";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
export default {
    components: {
        DateRangePicker,
        Multiselect,
        ListLoader,
    },
    data() {
        return {
            moment: moment,
            search: "",
            perPage: 10,
            isFilter: false,
            orderDate: {
                from: null,
                to: null,
            },
            serviceType: null,
            serviceTypes: [
                {
                    id: "trawlpack",
                    name: "Troben Cargo",
                },
                {
                    id: "trawltruck",
                    name: "Troben Truck",
                },
                {
                    id: "trawlcarrier",
                    name: "Troben Carier",
                },
            ],
            orderCategory: null,
            orderCategories: [
                {
                    id: "repeat",
                    name: "Repeat Order",
                },
                {
                    id: "new",
                    name: "New Order",
                },
            ],
            specialist: null,
            specialists: [],
            lists: null,
            isLoading: false,
            currentPage: 1,
            totalPages: 1,
        };
    },
    created() {
        this.getSpecialists();
        this.getLists();
    },
    methods: {
        formatDate(date) {
            return date ? moment(date).format("DD MMMM YYYY | HH:mm") : "";
        },
        getSpecialists() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist/dropdown`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.getLists();
        },
        getLists() {
            this.isLoading = true;
            const payload = {
                search: this.search,
                limit: this.perPage,
                page: this.currentPage - 1,
                service: this.serviceType?.id,
                category: this.orderCategory?.id,
                specialist_id: this.specialist?.id,
            };

            if (this.orderDate?.from && this.orderDate?.to) {
                payload.start_date = moment(this.orderDate.from).format(
                    "YYYY-MM-DD"
                );
                payload.end_date = moment(this.orderDate.from).format(
                    "YYYY-MM-DD"
                );
            }

            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/orders`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.totalPages = res.data.data.total_page || 1;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
    vertical-align: middle;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.red-badge {
    background-color: #e60013;
    color: #f6faff;
}
.green-badge {
    background-color: #3d8824;
    color: #f6faff;
}
.blue-badge {
    background-color: #48a2d4;
    color: #f6faff;
}
.link {
    color: #3c87b1;
    border-bottom: 1px solid #3c87b1;
}
.pagination-disabled {
    color: #d2d2d5;
    cursor: no-drop !important;
}
</style>
