<template>
    <div class="padding-container">
        <template v-if="page_content == 'main_page'">
            <div class="fw-bold">
                <a
                    href="/crm/customers/ordered"
                    class="text-gray-dashboard size-16"
                >
                    <i class="fa fa-angle-left me-2"></i>
                    Back
                </a>
            </div>

            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold">Detail Customers Ordered Data</h3>
                </div>
                <div class="mt-4">
                    <label class="fw-bold">Tags</label>
                    <div class="mt-2">
                        <template v-if="dataDetail.tags.length">
                            <template v-for="(tag, index) in dataDetail.tags">
                                <span class="tag gray radius" :key="index">{{
                                    tag
                                }}</span>
                            </template>
                        </template>
                        <template v-else> - </template>
                    </div>
                </div>
                <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                    <tr class="">
                        <td class="ps-0 w-200">Customer Name</td>
                        <td class="w-30">:</td>
                        <td>
                            <div class="d-flex align-items-center">
                                <div>
                                    {{
                                        dataDetail.name
                                            ? titleCase(dataDetail.name)
                                            : "-"
                                    }}
                                </div>
                                <div class="ms-auto capitalize">
                                    <img
                                        src="../../assets/platinum.png"
                                        width="16"
                                        class="me-1"
                                        alt=""
                                    />
                                    {{ dataDetail.label }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Phone Number</td>
                        <td class="w-30">:</td>
                        <td>{{ dataDetail.phone ? dataDetail.phone : "-" }}</td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Email</td>
                        <td class="w-30">:</td>
                        <td>{{ dataDetail.email ? dataDetail.email : "-" }}</td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Qty Paid</td>
                        <td class="w-30">:</td>
                        <td>{{ dataDetail.paid_order_qty }}</td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Last Order</td>
                        <td class="w-30">:</td>
                        <td>
                            {{
                                dataDetail.last_order
                                    ? dataDetail.last_order
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">First Order</td>
                        <td class="w-30">:</td>
                        <td>
                            {{
                                dataDetail.first_order
                                    ? dataDetail.first_order
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Money Paid</td>
                        <td class="w-30">:</td>
                        <td>
                            {{ currencyFormat(dataDetail.paid_order_amount) }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Lateness</td>
                        <td class="w-30">:</td>
                        <td>
                            {{
                                dataDetail.lateness
                                    ? dataDetail.lateness + "Hari"
                                    : "0 Hari"
                            }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Prospect Count</td>
                        <td class="w-30">:</td>
                        <td>
                            {{
                                dataDetail.prospect_count
                                    ? dataDetail.prospect_count + "x"
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Address</td>
                        <td class="w-30">:</td>
                        <td>
                            {{ dataDetail.address ? dataDetail.address : "-" }}
                        </td>
                    </tr>
                </table>
                <hr class="mt-1" />
                <div class="row">
                    <div class="col-md-2 mt-2">
                        <a
                            :href="
                                '/crm/customers/order/prospect/' +
                                this.$route.params.id
                            "
                            class="btn btn-outline-gray btn-lg w-100 radius size-16 fw-medium"
                        >
                            <img
                                src="../../assets/prospect.png"
                                width="19"
                                class="me-1"
                                alt=""
                            />
                            Prospect
                        </a>
                    </div>
                    <div class="col-md-2 mt-2">
                        <a
                            :href="
                                '/crm/customers/order/prospect/' +
                                this.$route.params.id +
                                '?type=mail'
                            "
                            class="btn btn-outline-gray btn-lg w-100 radius size-16 fw-medium"
                        >
                            <img
                                src="../../assets/mail.png"
                                width="19"
                                class="me-1"
                                alt=""
                            />
                            Send Mail
                        </a>
                    </div>
                    <div class="col-md-2 mt-2">
                        <a
                            href="javascript:void(0)"
                            @click="clickNotes()"
                            class="btn btn-outline-gray btn-lg w-100 radius size-16 fw-medium"
                        >
                            <img
                                src="../../assets/notes.png"
                                width="19"
                                class="me-1"
                                alt=""
                            />
                            Notes
                        </a>
                    </div>
                    <div class="col-md-3 mt-2">
                        <a
                            :href="
                                '/crm/customers/order/prospect/' +
                                this.$route.params.id +
                                '?type=meeting'
                            "
                            class="btn btn-outline-gray btn-lg w-100 radius size-16 fw-medium"
                        >
                            <img
                                src="../../assets/meeting.png"
                                width="19"
                                class="me-1"
                                alt=""
                            />
                            Google Meets
                        </a>
                    </div>
                    <div class="col-md-2 mt-2">
                        <button
                            @click="clickWA()"
                            class="btn btn-outline-gray btn-lg w-100 radius size-16 fw-medium"
                        >
                            <img
                                src="../../assets/wa.png"
                                width="20"
                                class="me-1"
                                alt=""
                            />
                            WhatsApp
                        </button>
                    </div>
                </div>
            </div>

            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3 me-auto">Ordered History</h3>
                </div>

                <div class="d-flex align-items-center no-flex">
                    <div class="me-4 m-mx-0">
                        <div class="position-relative mt-4">
                            <input
                                class="form-control form-control-lg pl-search"
                                v-model="req.search_ordered_history"
                                @input="searchOrderedHistory()"
                                type="text"
                                placeholder="Search"
                                style="width: 270px"
                            />
                            <span class="search-icon left">
                                <i class="fa fa-search fa-beat fa-sm"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="mt-4" v-if="is_ordered_history">
                    <ListLoader />
                </div>
                <div v-else>
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Resi
                                    </th>
                                    <th class="text-center" scope="col">
                                        Date
                                    </th>
                                    <th class="text-center" scope="col">
                                        Service
                                    </th>
                                    <th class="text-center" scope="col">
                                        Phone
                                    </th>
                                    <th class="text-center" scope="col">
                                        Route
                                    </th>
                                    <th class="text-center" scope="col">
                                        Assurance
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in dataOrder"
                                    :key="index"
                                >
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td class="text-center">
                                        <a
                                            :href="
                                                '/crm/customer/order/history/' +
                                                item.receipt_code
                                            "
                                            class="text-dark"
                                        >
                                            <u>
                                                {{
                                                    item.receipt_code
                                                        ? item.receipt_code
                                                        : "-"
                                                }}
                                            </u>
                                        </a>
                                    </td>
                                    <td class="text-center">
                                        {{
                                            moment(item.date).format(
                                                "DD/MM/YYYY"
                                            )
                                        }}
                                    </td>
                                    <td class="text-center capitalize">
                                        <template v-if="item.product != null">
                                            {{
                                                item.product
                                                    ? item.product
                                                    : "-"
                                            }}
                                        </template>
                                        <template v-else> TroPack </template>
                                    </td>
                                    <td class="text-center">
                                        {{ item.phone ? item.phone : "-" }}
                                    </td>
                                    <td class="text-center">
                                        {{ item.origin_regency }} -
                                        {{ item.destination_regency }}
                                    </td>
                                    <td class="text-center">
                                        {{ currencyFormat(item.insurance) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div
                        v-if="dataOrder.length == 0"
                        class="text-center mt-section"
                    >
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </div>
                <div class="overflow-auto mt-3">
                    <b-pagination-nav
                        v-if="dataOrder.length > 0"
                        v-model="currentPageOrdered"
                        :number-of-pages="totalPageOrdered"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePageOrderedHistory"
                    ></b-pagination-nav>
                </div>
            </div>

            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3 me-auto">Predictions History</h3>
                </div>

                <div class="mt-4" v-if="is_prediction_history">
                    <ListLoader />
                </div>
                <div v-else>
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Date
                                    </th>
                                    <th class="text-center" scope="col">
                                        Services
                                    </th>
                                    <th class="text-center" scope="col">
                                        Route
                                    </th>
                                    <th class="text-center" scope="col">
                                        Price
                                    </th>
                                    <th class="text-center" scope="col">
                                        Note
                                    </th>
                                    <th class="text-center" scope="col">
                                        Description
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in dataPrediction"
                                    :key="index"
                                >
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td class="text-center">
                                        {{
                                            moment(item.date).format(
                                                "DD/MM/YYYY"
                                            )
                                        }}
                                    </td>
                                    <td class="text-center capitalize">
                                        <template
                                            v-if="
                                                item.product ==
                                                    'trawlpack-goods' ||
                                                item.product == 'trawlpack-bike'
                                            "
                                        >
                                            TroPack
                                        </template>
                                        <template v-else>
                                            {{ item.product }}
                                        </template>
                                    </td>
                                    <td>
                                        {{ item.origin_regency }} -
                                        {{ item.destination_regency }}
                                    </td>
                                    <td class="text-center">
                                        {{ currencyFormat(item.amount) }}
                                    </td>
                                    <td class="text-center capitalize">
                                        <template
                                            v-if="item.product == 'trawltruck'"
                                        >
                                            {{
                                                (item.fleet_type
                                                    ? item.fleet_type
                                                    : "-"
                                                )
                                                    .split("_")
                                                    .join(" ")
                                            }}
                                        </template>
                                        <template
                                            v-else-if="
                                                item.product == 'trawlcarrier'
                                            "
                                        >
                                            {{
                                                (item.service_type
                                                    ? item.service_type
                                                    : "-"
                                                )
                                                    .split("_")
                                                    .join(" ")
                                            }}
                                        </template>
                                        <template v-else>
                                            {{
                                                item.weight ? item.weight : "-"
                                            }}
                                            Kg
                                        </template>
                                    </td>
                                    <td class="text-center">
                                        {{
                                            item.description
                                                ? item.description
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div
                        v-if="dataPrediction.length == 0"
                        class="text-center mt-section"
                    >
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </div>
                <div class="overflow-auto mt-3">
                    <b-pagination-nav
                        v-if="dataPrediction.length > 0"
                        v-model="currentPagePrediction"
                        :number-of-pages="totalPagePrediction"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePagePredictionHistory"
                    ></b-pagination-nav>
                </div>
            </div>

            <div class="box my-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3 me-auto">Activities History</h3>
                </div>

                <ul class="nav nav-pills mt-4" id="pills-tab" role="tablist">
                    <li class="nav-item w-20" role="presentation">
                        <button
                            class="nav-link dashboard left w-100 active"
                            id="pills-prospect-tab"
                            @click="getResult('prospect')"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-prospect"
                            type="button"
                            role="tab"
                            aria-controls="pills-prospect"
                            aria-selected="true"
                        >
                            Prospect
                        </button>
                    </li>
                    <li class="nav-item w-20" role="presentation">
                        <button
                            class="nav-link dashboard center w-100"
                            id="pills-email-tab"
                            @click="getResult('mail')"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-email"
                            type="button"
                            role="tab"
                            aria-controls="pills-email"
                            aria-selected="false"
                        >
                            Email
                        </button>
                    </li>
                    <li class="nav-item w-20" role="presentation">
                        <button
                            class="nav-link dashboard center w-100"
                            id="pills-notes-tab"
                            @click="getResult('notes')"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-notes"
                            type="button"
                            role="tab"
                            aria-controls="pills-notes"
                            aria-selected="false"
                        >
                            Notes
                        </button>
                    </li>
                    <li class="nav-item w-20" role="presentation">
                        <button
                            class="nav-link dashboard center w-100"
                            id="pills-meeting-tab"
                            @click="getResult('meeting')"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-meeting"
                            type="button"
                            role="tab"
                            aria-controls="pills-meeting"
                            aria-selected="false"
                        >
                            Meeting
                        </button>
                    </li>
                    <li class="nav-item w-20" role="presentation">
                        <button
                            class="nav-link dashboard right w-100"
                            id="pills-whatsapp-tab"
                            @click="getResult('whatsapp')"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-whatsapp"
                            type="button"
                            role="tab"
                            aria-controls="pills-whatsapp"
                            aria-selected="false"
                        >
                            WhatsApp
                        </button>
                    </li>
                </ul>

                <div class="tab-content" id="pills-tabContent">
                    <div
                        class="tab-pane fade show active mt-3"
                        id="pills-prospect"
                        role="tabpanel"
                        aria-labelledby="pills-prospect-tab"
                    >
                        <div class="mt-4" v-if="load_activities_history">
                            <ListLoader />
                        </div>
                        <div v-else>
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                Activities
                                            </th>
                                            <th class="text-center" scope="col">
                                                Owner
                                            </th>
                                            <th class="text-center" scope="col">
                                                Description
                                            </th>
                                            <th class="text-center" scope="col">
                                                Result
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in activities_history"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item?.date).format(
                                                        "DD/MM/YYYY"
                                                    )
                                                }}
                                            </td>
                                            <td class="text-center capitalize">
                                                {{
                                                    (item?.activity
                                                        ? item?.activity
                                                        : "-"
                                                    )
                                                        .split("_")
                                                        .join(" ")
                                                }}
                                            </td>
                                            <td class="text-center capitalize">
                                                {{
                                                    item?.sender
                                                        ? item?.sender
                                                        : "-"
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    item?.description
                                                        ? item?.description
                                                        : "-"
                                                }}
                                            </td>
                                            <td class="text-center capitalize">
                                                <span
                                                    class="tag w-100 radius"
                                                    v-bind:class="{
                                                        'green-solid':
                                                            item?.result ==
                                                            'deal',
                                                        'yellow-solid':
                                                            item?.result ==
                                                            'pending',
                                                        'red-solid':
                                                            item?.result ==
                                                            'lost',
                                                    }"
                                                >
                                                    {{
                                                        item?.result
                                                            ? item?.result
                                                            : "-"
                                                    }}
                                                </span>
                                                <p v-if="item?.result == 'deal'">{{ item?.result_info }}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div
                                v-if="activities_history.length == 0"
                                class="text-center mt-section"
                            >
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </div>
                        <div class="overflow-auto mt-3">
                            <b-pagination-nav
                                v-if="activities_history.length > 0"
                                v-model="currentPageActivitiesHistory"
                                :number-of-pages="totalPageActivitiesHistory"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePageCustomerOrdered()"
                            ></b-pagination-nav>
                        </div>
                    </div>

                    <div
                        class="tab-pane fade mt-3"
                        id="pills-email"
                        role="tabpanel"
                        aria-labelledby="pills-email-tab"
                    >
                        <div class="mt-4" v-if="load_activities_history">
                            <ListLoader />
                        </div>
                        <div v-else>
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                From
                                            </th>
                                            <th class="text-center" scope="col">
                                                To
                                            </th>
                                            <th class="text-center" scope="col">
                                                Cc
                                            </th>
                                            <th class="text-center" scope="col">
                                                Subject
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in activities_history"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item.date).format(
                                                        "DD/MM/YYYY"
                                                    )
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    item?.from
                                                        ? item?.from
                                                        : "-"
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{ item?.to ? item?.to : "-" }}
                                            </td>
                                            <td class="text-center">
                                                <template
                                                    v-if="item.cc != null"
                                                >
                                                    <template
                                                        v-for="(
                                                            cc, index
                                                        ) in item.cc"
                                                    >
                                                        <span :key="index">
                                                            {{ cc }}
                                                        </span>
                                                    </template>
                                                </template>
                                                <template v-else> - </template>
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    item?.subject
                                                        ? item?.subject
                                                        : "-"
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div
                                v-if="activities_history.length == 0"
                                class="text-center mt-section"
                            >
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </div>
                        <div class="overflow-auto mt-3">
                            <b-pagination-nav
                                v-if="activities_history.length > 0"
                                v-model="currentPageActivitiesHistory"
                                :number-of-pages="totalPageActivitiesHistory"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePage('mail')"
                            ></b-pagination-nav>
                        </div>
                    </div>

                    <div
                        class="tab-pane fade mt-3"
                        id="pills-notes"
                        aria-labelledby="pills-notes-tab"
                    >
                        <div class="mt-4" v-if="load_activities_history">
                            <ListLoader />
                        </div>
                        <div v-else>
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                Notes
                                            </th>
                                            <th class="text-center" scope="col">
                                                Files
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in activities_history"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item.date).format(
                                                        "DD/MM/YYYY"
                                                    )
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.note }}
                                            </td>
                                            <td class="text-center">
                                                <template
                                                    v-if="
                                                        item.attachment == null
                                                    "
                                                >
                                                    -
                                                </template>
                                                <template v-else>
                                                    <a
                                                        :href="
                                                            item.attachment_url
                                                        "
                                                        target="_blank"
                                                    >
                                                        <i
                                                            class="fa fa-paperclip me-1"
                                                        ></i>
                                                        See File
                                                    </a>
                                                </template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div
                                v-if="activities_history.length == 0"
                                class="text-center mt-section"
                            >
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </div>
                        <div class="overflow-auto mt-3">
                            <b-pagination-nav
                                v-if="activities_history.length > 0"
                                v-model="currentPageActivitiesHistory"
                                :number-of-pages="totalPageActivitiesHistory"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePageCustomerOrdered()"
                            ></b-pagination-nav>
                        </div>
                    </div>

                    <div
                        class="tab-pane fade mt-3"
                        id="pills-meeting"
                        aria-labelledby="pills-meeting-tab"
                    >
                        <div class="mt-4" v-if="load_activities_history">
                            <ListLoader />
                        </div>
                        <div v-else>
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                Time
                                            </th>
                                            <th class="text-center" scope="col">
                                                Meeting Type
                                            </th>
                                            <th class="text-center" scope="col">
                                                Description
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in activities_history"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item.date).format(
                                                        "DD/MM/YYYY"
                                                    )
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.meeting_time }}
                                            </td>
                                            <td class="text-center capitalize">
                                                {{ item.type }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.description }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div
                                v-if="activities_history.length == 0"
                                class="text-center mt-section"
                            >
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </div>
                        <div class="overflow-auto mt-3">
                            <b-pagination-nav
                                v-if="activities_history.length > 0"
                                v-model="currentPageActivitiesHistory"
                                :number-of-pages="totalPageActivitiesHistory"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePageCustomerOrdered()"
                            ></b-pagination-nav>
                        </div>
                    </div>

                    <div
                        class="tab-pane fade mt-3"
                        id="pills-whatsapp"
                        aria-labelledby="pills-whatsapp-tab"
                    >
                        <div class="mt-4" v-if="load_activities_history">
                            <ListLoader />
                        </div>
                        <div v-else>
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                Time
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in activities_history"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item.date).format(
                                                        "DD/MM/YYYY"
                                                    )
                                                }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item.date).format(
                                                        "HH:mm"
                                                    )
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div
                                v-if="activities_history.length == 0"
                                class="text-center mt-section"
                            >
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </div>
                        <div class="overflow-auto mt-3">
                            <b-pagination-nav
                                v-if="activities_history.length > 0"
                                v-model="currentPageActivitiesHistory"
                                :number-of-pages="totalPageActivitiesHistory"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePageCustomerOrdered()"
                            ></b-pagination-nav>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-vue">
                <div class="overlay" v-if="modalSuccess"></div>
                <div class="modal-body-chat vsm" v-if="modalSuccess">
                    <div class="mt-4 text-center">
                        <img
                            src="../../assets/dashboard/checklis.png"
                            width="80"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                    </div>
                    <div class="mt-4">
                        <a
                            href="/crm/customers/ordered"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </div>
                </div>
            </div>
        </template>

        <template v-if="page_content == 'notes'">
            <div class="fw-bold">
                <a
                    href="javascript:void(0)"
                    @click="page_content = 'main_page'"
                    class="text-gray-dashboard size-16"
                >
                    <i class="fa fa-angle-left me-2"></i>
                    Back
                </a>
            </div>
            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3">Add New Notes</h3>
                </div>
                <div class="mt-3">
                    <label class="fw-bold mb-2">Notes</label>
                    <textarea
                        rows="7"
                        cols="30"
                        class="form-control"
                        placeholder="Type Here"
                        v-model="req.notes"
                    ></textarea>
                </div>
                <div class="mt-3">
                    <label class="fw-bold">Document (Optional)</label>
                    <div class="text-gray mb-2">Document max 5MB</div>
                    <label
                        type="file"
                        :class="!attachment ? 'upload__btn' : ''"
                    >
                        <img
                            v-if="attachment"
                            class="photo"
                            :src="attachmentPictureUrl"
                        />
                        <p v-else class="mb-0">
                            <i class="fa fa-plus"></i>
                        </p>
                        <input
                            class="cursor-pointer"
                            id="upload"
                            type="file"
                            name="file"
                            ref="file"
                            @change="uploadPhoto"
                        />
                    </label>
                </div>
                <div class="mt-4">
                    <button
                        class="btn btn-green btn-lg px-5"
                        type="button"
                        disabled
                        v-if="is_save_notes"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Save
                    </button>
                    <button
                        type="submit"
                        v-else
                        class="btn btn-green btn-lg px-5"
                        @click="saveNotes()"
                        :disabled="req.notes == ''"
                    >
                        Save
                    </button>
                </div>
            </div>

            <div class="modal-vue">
                <div class="overlay" v-if="modalSuccessNotes"></div>
                <div class="modal-body-chat vsm" v-if="modalSuccessNotes">
                    <div class="mt-4 text-center">
                        <img
                            src="../../assets/dashboard/checklis.png"
                            width="80"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4">
                            Create Notes Succeeded!
                        </h4>
                    </div>
                    <div class="mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="successNotes()"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import noImage from "../../assets/no-photo.png";

export default {
    name: "customersOrderedDetail",
    components: {
        ListLoader,
    },
    data() {
        return {
            moment: moment,
            dataDetail: [],
            dataOrder: [],
            dataPrediction: [],
            dataHistory: [],
            dataProspect: "",
            keySearchProspect: "",
            typeResult: "prospect",
            formFrom: "cs@trawlbens.id",
            formTo: "",
            formSubject: "",
            formContent: "",
            formNotes: "",
            attachments: [],
            is_ordered_history: false,
            is_prediction_history: false,
            is_my_list_ready_prospect: false,
            is_my_list_ready_history: false,
            is_save: false,
            modalSuccess: false,
            currentPageOrdered: 0,
            totalPageOrdered: 0,
            limitOrdered: 10,
            totalPagePrediction: 0,
            limitPrediction: 10,
            currentPagePrediction: 0,
            currentPageProspect: 0,
            totalPageProspect: 0,
            limitProspect: 10,
            req: {
                search_ordered_history: "",
                search_prediction_history: "",
                search_activities_history: "",
                mail_from: "sales@trawlbens.id",
                mail_to: "",
                mail_cc: [],
                mail_subject: "",
                mail_content: "",
                notes: "",
                attachment: "",
            },
            tags: [],
            page_content: "main_page",
            mail_to: "",
            item_id: null,
            modalSuccessEmail: false,
            is_save_mail: false,
            is_save_notes: false,
            modalSuccessNotes: false,
            attachment: "",
            load_activities_history: false,
            currentPageActivitiesHistory: 0,
            totalPageActivitiesHistory: 0,
            limitActivitiesHistory: 10,
            activities_history: [],
        };
    },
    created() {
        this.getDataDetail();
        this.getDataOrder();
        this.getDataProspect();
        this.getPredictionHistory();
        this.getActivitiesHistory();
    },
    methods: {
        clickNotes() {
            this.page_content = "notes";
            this.item_id = this.$route.params.id;
        },
        clickWA() {
            window.open("https://wa.me/6281399000000", "_blank");
            window.location.href =
                "/crm/customers/order/prospect/" +
                this.$route.params.id +
                "?type=whatsapp";
        },
        saveNotes() {
            this.is_save_notes = true;
            var data = new FormData();

            data.append("customer_id", this.item_id);
            data.append("type", "order");
            data.append("content", this.req.notes);
            data.append("attachment", this.req.attachment);
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/note/create`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_save_notes = false;
                    this.modalSuccessNotes = true;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_save_notes = false;
                });
        },
        successNotes() {
            window.location.href =
                "/crm/customer/order/detail/" + this.$route.params.id;
        },
        uploadPhoto(event) {
            var input = event.target;
            this.req.attachment = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.attachment = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        searchData() {
            // this.getDataOrder();
            this.getDataProspect();
        },
        changePage(value) {
            if (value == "ordered") {
                this.getDataOrder();
            }
            if (value == "prospect") {
                this.getDataProspect();
            }
        },
        changePageCustomerOrdered() {
            this.getActivitiesHistory();
        },
        getResult(value) {
            this.typeResult = value;
            this.getActivitiesHistory();
        },
        getDataDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/order/detail`,
                {
                    params: { customer_id: this.$route.params.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.dataDetail = res.data?.data;
                    this.formTo = this.dataDetail?.email;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        changePageOrderedHistory() {
            this.getDataOrder();
            // this.changePage("ordered");
        },
        searchOrderedHistory() {
            this.getDataOrder();
        },
        getDataOrder() {
            this.is_ordered_history = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/order/list`,
                {
                    params: {
                        customer_id: this.$route.params.id,
                        product: "trawlpack",
                        page:
                            this.currentPageOrdered == 0 ||
                            this.currentPageOrdered == null
                                ? 0
                                : this.currentPageOrdered - 1,
                        limit: this.limitOrdered,
                        search: this.req.search_ordered_history,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.dataOrder = res.data.data.list_data;
                    this.totalPageOrdered = res.data.data.total_page;
                    this.is_ordered_history = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_ordered_history = false;
                });
        },
        changePagePredictionHistory() {
            this.getPredictionHistory();
            this.changePage("ordered");
        },
        searchPredictionHistory() {
            this.getPredictionHistory();
        },
        getPredictionHistory() {
            this.is_prediction_history = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/order/predictions`,
                {
                    params: {
                        customer_id: this.$route.params.id,
                        type: "customer",
                        product: "trawlpack",
                        page:
                            this.currentPagePrediction == 0 ||
                            this.currentPagePrediction == null
                                ? 0
                                : this.currentPagePrediction - 0,
                        limit: this.limitPrediction,
                        search: this.req.search_prediction_history,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.dataPrediction = res.data.data.list_data;
                    this.totalPagePrediction = res.data.data.total_page;
                    this.is_prediction_history = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_prediction_history = false;
                });
        },
        getActivitiesHistory() {
            this.load_activities_history = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/order/activities`,
                {
                    params: {
                        customer_id: this.$route.params.id,
                        type: "order",
                        tab: this.typeResult,
                        page:
                            this.currentPageActivitiesHistory == 0 ||
                            this.currentPageActivitiesHistory == null
                                ? 0
                                : this.currentPageActivitiesHistory - 1,
                        limit: this.limitActivitiesHistory,
                        search: this.search_activities_history,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.activities_history = res.data.data?.list_data;
                    this.totalPageActivitiesHistory = res.data.data?.total_page;
                    this.load_activities_history = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.load_activities_history = false;
                });
        },
        getDataProspect() {
            this.is_my_list_ready_prospect = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/prospect`,
                {
                    params: {
                        customer_id: this.$route.params.id,
                        page:
                            this.currentPageProspect == 0 ||
                            this.currentPageProspect == null
                                ? 0
                                : this.currentPageProspect - 1,
                        limit: this.limitProspect,
                        search: this.keySearchProspect,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.dataProspect = res.data.data?.list_data;
                    this.totalPageProspect = res.data.data?.total_page;
                    this.is_my_list_ready_prospect = false;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                    this.is_my_list_ready_prospect = false;
                });
        },
        sendNote() {
            var data = new FormData();

            data.append("customer_id", this.$route.params.id);
            data.append("content", this.formNotes);

            this.is_save = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/note/create`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success!!!",
                        text: `Send Notes Success`,
                    });

                    this.getDataHistory();
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDataHistory() {
            this.is_my_list_ready_history = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/customer/note`, {
                params: {
                    customer_id: this.$route.params.id,
                    page: -1,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.dataHistory = res.data.data?.list_data;
                    this.is_my_list_ready_history = false;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                    this.is_my_list_ready_history = false;
                });
        },
        sendEmail() {
            //   var data = new FormData();

            //   data.append("from", this.formFrom);
            //   data.append("to", this.formTo);
            //   data.append("subject", this.formSubject);
            //   data.append("content", this.formContent);
            //   if (this.attachments.length > 0) {
            //     data.append("attachments", this.attachments);
            //   }
            let data = {
                from: this.formFrom,
                to: this.formTo,
                subject: this.formSubject,
                content: this.formContent,
                attachments: [
                    {
                        type: null,
                        file: null,
                    },
                ],
            };

            this.is_save = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/email/send`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success!!!",
                        text: `Send Email Success`,
                    });
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        setPacking(array) {
            let packing = array.map((el) => {
                return this.codePacking(el);
            });
            return packing.join(", ");
        },
        setItemType(array) {
            let that = this;
            let packing = array.map((el) => {
                return that.titleCase(el);
            });
            return packing.join(", ");
        },
        codePacking(value) {
            var packing;
            switch (value) {
                case "bubble wrap":
                    packing = "Bubble Wrap";
                    break;
                case "cardboard":
                    packing = "Kardus";
                    break;
                case "plastic":
                    packing = "Plastik";
                    break;
                case "wood":
                    packing = "Kayu";
                    break;
                case "sandbag sm":
                    packing = "Karung Kecil";
                    break;
                case "sandbag md":
                    packing = "Karung Sedang";
                    break;
                case "sandbag l":
                    packing = "Karung Besar";
                    break;
                default:
                    packing = "";
            }
            return packing;
        },
        setStatus(value) {
            if (value == "not_started") return "Not Started";
            if (value == "in_progress") return "In Progress";
            if (value == "deferes") return "Deferes";
            if (value == "completed") return "Completed";
        },
        checkStat(value) {
            if (value == "not_started") return "pill red";
            if (value == "in_progress") return "pill blue";
            if (value == "deferes") return "pill yellow";
            if (value == "completed") return "pill green";
        },
        checkResult(value) {
            if (value == "deal") return "pill green";
            if (value == "pending") return "pill yellow";
            if (value == "lost") return "pill red";
        },
        getExport() {},
        dateFormated(date) {
            return moment(date, "DD MM YYYY HH MM").format("DD/MM/YYYY");
        },
        itemNumber(index) {
            let number = index + this.startIndex + 1;
            return (number < 10 ? "0" : "") + number;
        },
    },
    computed: {
        attachmentPictureUrl() {
            return this.attachment != "" ? this.attachment : noImage;
        },
        startIndex() {
            return (this.currentPage - 1) * this.limit;
        },
    },
};
</script>
<style lang="scss" scoped>
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px;
}
tr {
    border-color: #e8e8e9;
    padding: 10px;
}
td {
    padding: 10px;
}
.left-50 {
    position: absolute;
    left: 50px;
}
.left-80 {
    position: relative;
    left: 80px;
}
.w-30 {
    width: 30px;
}
.w-200 {
    width: 200px;
}
.w-1041 {
    width: 1041px;
}
.h-126 {
    height: 126px;
}
.h-666 {
    height: 666px;
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white;
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }

    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
    &.yellow {
        background-color: #fb9727;
        border: 1px solid #fb9727;
        outline: 1px solid #fb9727;
    }
    &.blue {
        background-color: #48a2d4;
        border: 1px solid #48a2d4;
        outline: 1px solid #48a2d4;
    }
}
.bt-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 159px !important;
    height: 44px !important;
    border-radius: 8px;
}
.text-gray-dashboard {
    color: #61616a;
}
.search-icon-left {
    top: 11px;
    left: 10px;
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.nav-link.dashboard.center {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
.w-33 {
    width: 33% !important;
}
.w-20 {
    width: 20% !important;
}
.w-34 {
    width: 34% !important;
}
.border-top {
    border-color: #eee !important;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
}
.btn-lg {
    width: 150px;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    font-size: 30px;
}
</style>
