<template>
    <div class="padding-container">
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Register Driver</h3>
            </div>
            <div class="box mt-3">
                <label class="fw-bold">Filter Data</label>
                <div class="row">
                    <div class="col-md-4 mt-2">
                        <input
                            type="text"
                            class="form-control w-search-leads"
                            v-model="search"
                            placeholder="Masukkan Nama atau Nomor Telepon"
                        />
                    </div>
                    <div class="col-md-4 mt-2">
                        <multiselect
                            v-model="armada"
                            :options="armadas"
                            label="name"
                            :loading="is_load"
                            track-by="code"
                            placeholder="Pilih Armada"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 mt-2">
                        <multiselect
                            v-model="regency"
                            :options="regencies"
                            label="name"
                            :loading="is_load"
                            track-by="id"
                            placeholder="Pilih kota/kabupaten"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-2">
                        <select
                            class="form-control form-select"
                            v-model="status"
                        >
                            <option value="">Pilih Status</option>
                            <option value="accept">Accept</option>
                            <option value="pending">Pending</option>
                            <option value="reject">Reject</option>
                            <option value="banned">Banned</option>
                        </select>
                    </div>
                    <div class="col-md-6 mt-2">
                        <select
                            class="form-control form-select"
                            v-model="filter"
                        >
                            <option value="">Pilih Urutan</option>
                            <option value="desc">Terbaru</option>
                            <option value="asc">Terlama</option>
                        </select>
                    </div>
                    <div class="col-md-6 mt-2">
                        <multiselect
                            v-model="regency"
                            :options="regencies"
                            label="name"
                            :loading="is_load_regency"
                            track-by="id"
                            placeholder="Pilih kota/kabupaten"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-2 mt-3">
                        <button
                            class="btn btn-outline-black px-5 w-100"
                            :disabled="
                                search == '' &&
                                armada == null &&
                                status == '' &&
                                filter == '' &&
                                regency == null
                            "
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2 mt-3">
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="
                                search == '' &&
                                armada == null &&
                                status == '' &&
                                filter == '' &&
                                regency == null
                            "
                            @click="submitFilter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Driver
                                </th>
                                <th class="text-center" scope="col">
                                    Nomor Telepon
                                </th>
                                <th class="text-center" scope="col">
                                    Kota/Kabupaten
                                </th>
                                <th class="text-center" scope="col">
                                    Nomor Polisi
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Armada
                                </th>
                                <th class="text-center" scope="col">
                                    Tanggal Register
                                </th>
                                <th class="text-center" scope="col">Status</th>
                                <th class="text-center" scope="col">
                                    Reject Count
                                </th>
                                <th class="text-center" scope="col">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in data.list_data"
                                :key="index"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="">
                                    <router-link
                                        class="text-link"
                                        :to="{
                                            name: 'detail-account-driver',
                                            params: {
                                                id: item.id,
                                                type: 'register',
                                                data: item,
                                            },
                                        }"
                                    >
                                        {{ item?.name }}
                                    </router-link>
                                </td>
                                <td class="text-center">
                                    {{ item.phone ? item.phone : "-" }}
                                </td>
                                <td>
                                    {{
                                        item.driver_truck?.regency?.name
                                            ? item.driver_truck?.regency?.name
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.fleet_truck?.fleet_number
                                            ? item.fleet_truck?.fleet_number
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.fleet_truck?.fleet_type_label
                                            ? item.fleet_truck?.fleet_type_label
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center">
                                    {{
                                        moment(item.created_at).format(
                                            "DD MMMM YYYY | HH:mm"
                                        )
                                    }}
                                </td>
                                <td class="text-center" style="width: 130px">
                                    <div
                                        class="tag radius w-100 capitalize"
                                        v-bind:class="{
                                            panding:
                                                item.driver_truck.status ==
                                                'pending',
                                            resubmit:
                                                item.driver_truck.status ==
                                                'resubmit',
                                            reject:
                                                item.driver_truck.status ==
                                                'reject',
                                            banned:
                                                item.driver_truck.status ==
                                                'banned',
                                        }"
                                    >
                                        {{ item.driver_truck.status }}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div
                                        @click="openModalCount(item)"
                                        v-if="
                                            item.driver_truck.rejections_count >
                                            0
                                        "
                                        v-bind:class="{
                                            'text-link cursor-pointer':
                                                item.driver_truck
                                                    .rejections_count > 0,
                                        }"
                                    >
                                        {{ item.driver_truck.rejections_count }}
                                    </div>
                                    <div v-else>
                                        {{ item.driver_truck.rejections_count }}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <template
                                        v-if="
                                            item.driver_truck.status ==
                                                'reject' ||
                                            item.driver_truck.status == 'banned'
                                        "
                                    >
                                        <img
                                            src="../../assets/check-gray.png"
                                            width="20"
                                            class="me-2"
                                            alt=""
                                        />
                                        <img
                                            src="../../assets/close-gray.png"
                                            width="20"
                                            alt=""
                                        />
                                    </template>
                                    <template v-else>
                                        <a
                                            href="javascript:void(0)"
                                            @click="showModalDriver(item.id)"
                                            class="text-green size-20 me-2"
                                        >
                                            <img
                                                src="../../assets/check-green.png"
                                                width="20"
                                                alt=""
                                            />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            @click="openModalReject(item.id)"
                                            class="text-green size-20"
                                        >
                                            <img
                                                src="../../assets/close-red.png"
                                                width="20"
                                                alt=""
                                            />
                                        </a>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="data.list_data.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex" v-if="data.list_data.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                            @change="getList()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        v-model="currentPage"
                        :number-of-pages="data.total_page"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changeSearch"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalDriver"></div>
            <div class="modal-body-chat vsm" v-if="modalDriver">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Apakah Kamu Yakin ingin menambahkan data ini sebagai
                        Driver?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-red w-100 btn-lg"
                                @click="modalDriver = false"
                            >
                                Batal
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-green w-100 btn-lg"
                                @click="acceptDriver()"
                                :disabled="is_accept"
                            >
                                <span
                                    class="spinner-border spinner-border-sm me-2"
                                    v-if="is_accept"
                                ></span>
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalReject"></div>
            <div class="modal-body-chat vsm" v-if="modalReject">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Pendaftaran driver ini akan ditolak!
                    </div>
                    <div class="text-gray-dashboard text-center">
                        Mohon lengkapi checklist sheet dan berikan alasan
                        penolakan
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-red w-100 btn-lg"
                                @click="modalReject = false"
                            >
                                Batal
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <a
                                :href="`/trawltruck/driver/registration/reject/${reject_id}`"
                                class="btn btn-green w-100 btn-lg"
                            >
                                Ok
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalCount"></div>
            <div class="modal-body-chat sm" v-if="modalCount">
                <h4 class="fw-bold">Alasan Penolakan</h4>
                <ul class="timeline timeline-split mt-4">
                    <li
                        class="timeline-item"
                        v-for="i in rejections.length"
                        :set="(reject = rejections[rejections.length - i])"
                        :key="i"
                    >
                        <div
                            class="timeline-marker"
                            v-bind:class="{
                                'active-red': i == 1,
                            }"
                        ></div>
                        <div class="timeline-content">
                            <div
                                class="timeline-title"
                                v-bind:class="{
                                    'text-dark': i == 1,
                                }"
                            >
                                Penolakan
                                <template v-if="rejections.length == 3">
                                    <template v-if="i == 1">Ketiga</template>
                                    <template v-if="i == 2">Kedua</template>
                                    <template v-if="i == 3">Pertama</template>
                                </template>
                                <template v-if="rejections.length == 2">
                                    <template v-if="i == 1">Kedua</template>
                                    <template v-if="i == 2">Pertama</template>
                                </template>
                                <template v-if="rejections.length == 1">
                                    <template v-if="i == 1">Pertama</template>
                                </template>
                                -
                                <span
                                    v-bind:class="{
                                        'text-danger': i == 1,
                                        'text-gray-dashboard': i > 1,
                                    }"
                                >
                                    {{
                                        moment(reject.updated_at).format(
                                            "DD MMM YYYY | HH:mm"
                                        )
                                    }}
                                </span>
                            </div>
                            <div class="mt-1">
                                <small
                                    class="tag"
                                    v-bind:class="{
                                        reject: i == 1,
                                        gray: i > 1,
                                    }"
                                >
                                    {{ reject.meta.reason }}
                                </small>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="mt-2">
                    <button
                        class="btn btn-green w-100 btn-md"
                        @click="modalCount = false"
                    >
                        Tutup
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    name: "Register Driver",
    data() {
        return {
            moment: moment,
            showModal: false,
            data: {
                list_data: [],
            },
            currentPage: 0,
            limit: 10,
            search: "",
            is_load: false,
            modalDriver: false,
            driver_id: null,
            is_accept: false,
            modalCount: false,
            modalReject: false,
            reject_id: null,
            rejections: [],
            armada: null,
            armadas: [],
            status: "",
            pics: [],
            filter: "",
            regency: null,
            regencies: [],
            is_load_regency: false,
        };
    },
    created() {
        this.getList();
        this.getListArmada();
        //this.getListPic();
        this.getRegencies();
    },
    computed: {},
    methods: {
        getRegencies() {
            this.is_load_regency = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                    this.is_load_regency = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_regency = false;
                });
        },
        showModalDriver(id) {
            this.driver_id = id;
            this.modalDriver = true;
        },
        acceptDriver() {
            this.is_accept = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/register/accept`,
                {
                    user_id: this.driver_id,
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Berhasil ditambahkan sebagai driver",
                    });
                    this.modalDriver = false;
                    this.is_accept = false;
                    this.getList();
                })
                .catch((err) => {
                    this.modalDriver = false;
                    this.is_accept = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        changeSearch() {
            this.getList();
        },
        getList() {
            this.is_load = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/register/list`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        search: this.search,
                        type_fleet: this.armada?.code,
                        status: this.status,
                        filter: this.filter,
                        regency_id: this.regency?.id,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_load = false;
                    this.data = res.data.data;
                })
                .catch((err) => {
                    this.is_load = false;
                    console.log(err);
                });
        },
        getListArmada() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/web/v2/trawltruck/fleet`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.armadas = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        openModalCount(item) {
            this.rejections = item.driver_truck.rejections;
            this.modalCount = true;
        },
        openModalReject(id) {
            this.reject_id = id;
            this.modalReject = true;
        },
        submitFilter() {
            this.getList();
        },
        clearFilter() {
            (this.search = ""), (this.armada = null);
            this.status = "";
            (this.filter = ""), this.getList();
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
small {
    display: inline-block;
    max-width: 100%;
    white-space: normal;
}
</style>
